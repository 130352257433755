:root{
  --iota-blue-1 : rgb(107,186,192);
  --iota-blue-2 : rgb(128,207,217);
  --iota-blue-3 : rgb(154,216,223);
  --iota-blue-4 : rgb(179,224,230);  
  --iota-blue-5 : rgb(223,241,242);  
  --iota-purple-1 : rgb(77,36,99);
  --iota-purple-2 : rgb(107,59,135);
  --iota-purple-3 : rgb(129,78,158);
  --iota-purple-4 : rgb(168,137,190);  
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Montserrat','Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--iota-blue-3);
 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.icon-spin {
  -webkit-animation: icon-spin 3s infinite linear;
          animation: icon-spin 3s infinite linear;
}

.icon-spin-anti {
  -webkit-animation: icon-spin-anti 1s infinite linear;
          animation: icon-spin-anti 1s infinite linear;
}
.jsonedit button{
  color: white;
  text-decoration: none;
  border-radius: 7px;
  border: none;
  text-transform: lowercase;
  height:27px;
}
.jsonedit select{
  margin-right: 1rem;
}

.jsonedit div[class^="styles-module_clickNode"] {
  height:30px;
}
/* Branding quick fix */
.MuiTab-textColorPrimary.Mui-selected{
  color: var(--iota-purple-3) !important;
  font-weight: bold !important;
}

.MuiTab-textColorPrimary{
  color: var(--iota-blue-1) !important;
  font-weight: bold !important;
}

.MuiDataGrid-columnHeaders{
  border-color: var(--iota-blue-1) !important;
}

.MuiDataGrid-root {
  border-color: var(--iota-blue-1) !important;
}
.MuiDataGrid-columnHeaderTitleContainer{
  border-color: var(--iota-blue-1) !important;
}
.MuiDataGrid-columnSeparator{
  color: var(--iota-blue-1) !important;
}
.MuiDataGrid-cell{
  border-color: var(--iota-blue-1) !important;
}
.MuiDataGrid-cellContent{
  font-weight: bold;
  color: var(--iota-purple-3) !important;
}
.MuiDataGrid-columnHeaderTitle{
  font-weight: bold !important;
  color: var(--iota-purple-3) !important;
  font-variant-caps: all-small-caps;
}


.MuiInputBase-root{
  background-color: var(--iota-blue-4);
}
.MuiTypography-root{
  font-weight: 600 !important;
}
.MuiPaper-root{
  background-color: var(--iota-blue-4) !important;
}
.menubox{
  background-color: var(--iota-blue-2) !important;
}

.hero-image-left{
  border:thick var(--iota-blue-1) solid;
  animation: helloHeroLeft 1s;
  object-position: -300px 

}

.hero-image-right{
  border:thick var(--iota-blue-1) solid;
  animation: helloHeroRight 1.5s;
  object-position: -290px 
}

@keyframes helloHeroLeft {
  0% { opacity: 0;
       object-position: -500px  }
  100% { opacity: 1;
    object-position: -300px }
}

@keyframes helloHeroRight {
  0% { opacity: 0;
       object-position: 0px  }
  100% { opacity: 1;
    object-position: -290px }

}
.MuiTableHead-root .MuiTableCell-root {
  color: var(--iota-blue-1) !important;
}
.MuiTableBody-root .MuiTableCell-root {
  color: var(--iota-blue-4) !important;
}
.MuiTableCell-body, .MuiTableCell-head{
  border: thin var(--iota-blue-1) solid !important;
  
}

img{
  z-index: 0;
}

.hero-button{
  
  opacity: 1;
  transition: opacity 250ms;
}
.hero-button:hover{
  
  opacity: 0.6;
  transition: opacity 500ms;
}
.hero-login{
  width: 50%;
  height: 50%; 
  object-fit: contain;
  animation: fadeIn 5s
}

.hero-menu-image { 
  width: 150px;
  object-fit: contain;
  overflow: hidden;
  animation: fadeRight 3s; 
}

@keyframes fadeRight {
  0% { opacity: 0;

    overflow: hidden;}
  100% { opacity: 1;

   
  }
}


.fadeInImage { animation: fadeIn 5s; }

@keyframes fadeIn {
  0% { opacity: 0;
       bottom: -50px  }
  100% { opacity: 1;
        bottom: 0 }
}

.bottomView img{
  position: absolute;
  bottom: 0;
  right: 0;
  
}



@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@-webkit-keyframes icon-spin-anti {
  0% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359eg);
  }
  100% {
    -webkit-transform: rotate(0eg);
            transform: rotate(0deg);
  }
}

@keyframes icon-spin-anti {
  0% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.MuiButtonBase-root { 
  padding: 8px !important; 
}
.MuiDataGrid-row.Mui-selected {
  background-color: rgba(156, 39, 176, 0.1) !important;
}
.MuiDataGrid-row.Mui-selected:hover {
  background-color: rgba(156, 39, 176, 0.5) !important;
}
 
.uplift-report .MuiDataGrid-columnHeader{
  background-color: rgba(156, 39, 176, 0.5); 
  color: white;

}

.uplift-report .MuiDataGrid-iconSeparator:last-child {
  visibility: hidden;
}

.styles-module_overlay__3xf0A + div {
  bottom:10% !important;
  right:50% !important;
}

.styles-module_overlay__3xf0A{
  height: 50% !important;
}
.styles-module_editorContainer__3rgkN {
  height: 500px !important;
}

.jsonEdit button {
    border-radius: 12px;
    border: none;
    color: white;
    padding: 2px 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
}


.screenContainer{
    height: 100%;
}
.mainContainer{
    height: calc(100% - 3em);

}
.container {
    display: flex;
    flex-direction: row;
    font-size: 1.1em;
    padding: .5em;
    align-items: flex-start;
    justify-content: center;
}
.topContainer{
    font-size: 1.5em;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    height: 3em;
    align-items: center;
}
.viwContainer {
    display: flex;
    height: calc(100% - 5em);
    width: 100%
}

.footer{
    display: block;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #323232;
    padding: 1em;
    color: white;

}

.jsonContainer{
    display: flex;
    padding: 1em;
    flex: 45%;
    border: 1px solid lightgray;
    border-radius: 6px;
    background-color: white;

}

.jsonContainer textarea {
    padding: .75em;
    width: 100%;
    font-size: 1.1em;
}

.middleContainer {
    min-width: 5em;
    flex: 5%;
    display: block;
    margin: auto;
    text-align: center;

}

.toString{
    background-color: gray;
}
.arrowLeft{
    margin-right: 8px;
    border: solid rgba(255,255,255,0.9);
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.output{
    flex: 50%;
    height: 100%;
    display: flex;
    border: 1px solid rgba(0,0,0,.2);
}

